<template>
  <b-dropdown
    ref="dd"
    :value="value"
    :multiple="multiple"
    :disabled="readonly"
    class="is-select tbs-dropdown"
    :class="{ 'is-multiple': 'multiple' }"
    aria-role="list"
    @input="$emit('input', $event)"
    @active-change="positionDropdown"
  >
    <template #trigger="{ active }">
      <span slot="trigger" class="button">
      <a href="#" class="mr-1" @click.prevent>
        <span :class="['fas', `fa-chevron-${active ? 'up' : 'down'}`]"/>
      </a>
      <span class="selected-label">{{ $t(selectedLabel) }}</span>
    </span>
    </template>

    <a v-if="allowEmpty && multiple" role="listitem" tabindex="0" class="dropdown-item" :class="{ 'is-active': value.length === 0 }" @click.prevent.stop="toggleAll">
      <span>{{ $t(allowEmpty) }}</span>
    </a>
    <b-dropdown-item v-if="allowEmpty && !multiple" aria-role="listitem" value="">
      <span>{{ $t(allowEmpty) }}</span>
    </b-dropdown-item>
    <b-dropdown-item v-for="column in items" :key="column.field" :value="column.field" aria-role="listitem" :data-tst-value="column.field">
      <span v-if="column">{{ $t(column.label) }}</span>
    </b-dropdown-item>
  </b-dropdown>
</template>
<script>
export default {
  name: 'TableFilterSelect',
  props: {
    allowEmpty: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Array],
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    selectedLabel() {
      const values = (Array.isArray(this.value) ? this.value : [this.value]).filter(item => item);

      if (values.length > 0) {
        return values
          .map(val => {
            const item = this.items.find(item => item.field === val);
            if (item) {
              return item.label;
            }
          })
          .join(', ');
      }

      return this.allowEmpty;
    }
  },
  methods: {
    positionDropdown() {
      const mainEl = this.$refs.dd.$el;
      const mainRect = mainEl.getBoundingClientRect();

      const dropdownEl = mainEl.querySelector('.dropdown-menu');

      const wheight = window.innerHeight;

      if (wheight - mainRect.bottom >= mainRect.top) {
        dropdownEl.style.top = mainRect.bottom + 'px';
        dropdownEl.style.bottom = 'initial';
      } else {
        dropdownEl.style.top = 'initial';
        dropdownEl.style.bottom = wheight - mainRect.top + 'px';
      }

      dropdownEl.style.left = mainRect.left + 'px';
      dropdownEl.style.minWidth = 'initial';
    },
    toggleAll() {
      this.$emit('input', []);
      this.$refs.dd.toggle();
    }
  }
};
</script>
<style lang="scss" scoped>
.selected-label {
  text-overflow: ellipsis;
  max-width: 15em;
  overflow: hidden;
}
.tbs-dropdown {
  ::v-deep .dropdown-menu {
    position: fixed;
  }
}
</style>
